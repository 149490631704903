@font-face {
    font-family: 'Source-Sans-Pro-bold';
    src: url('../fonts/SourceSansPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro-boldI';
    src: url('../fonts/SourceSansPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro-italic';
    src: url('../fonts/SourceSansPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro-regular';
    src: url('../fonts/SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro-semibold';
    src: url('../fonts/SourceSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source-Sans-Pro-semibold-italic';
    src: url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}