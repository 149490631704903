/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

@import 'assets/stylesheets/fonts.scss';

@import "primeicons/primeicons.css";

.bg-main
{
    // background-image: url("./assets/img/bg.jpeg");
    // background-size: cover;
    // background-repeat: no-repeat;
    // height: 100%;
    // min-height: 890px;
    background-color: #428bca;
}

.edit-icon
{
    float:right;
    font-size: 16px !important;
    margin-right: 40px !important;
    cursor: pointer;
}

.edit-icon-nofloat
{
    // float:right;
    font-size: 16px !important;
    margin-right: 40px !important;
    cursor: pointer;
}

.edit-icon-nofloat2
{
    font-size: 16px !important;
    cursor: pointer;
}

.edit-icon-nofloat3
{
    // float:right;
    font-size: 16px !important;
    margin-right: 20px !important;
    cursor: pointer;
}

.edit-icon-20
{
    float:right;
    font-size: 16px !important;
    margin-right: 20px !important;
    cursor: pointer;
}

label
{
    font-family: 'Source-Sans-Pro-regular';
}
.btn
{
    font-family: 'Source-Sans-Pro-regular';
    color: #ffffff;
}

// from aytra core helpers css
// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

  
  .m-0 {
    margin: 0 0 !important;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mr-0 {
    margin-right: 0 !important;
  }
  
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0 {
    margin-left: 0 !important;
  }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .m-1 {
    margin: 1rem 1rem !important;
  }
  
  .mr-1 {
    margin-right: 1rem !important;
  }
  
  .mb-1 {
    margin-bottom: 1rem !important;
  }
  
  .ml-1 {
    margin-left: 1rem !important;
  }
  
  .mx-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  
  .my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  
  .m-2 {
    margin: 1.5rem 1.5rem !important;
  }
  
  
  .mr-2 {
    margin-right: 1.5rem !important;
  }
  
  .mb-2 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-2 {
    margin-left: 1.5rem !important;
  }
  
  .mx-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  
  .my-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  
  .m-3 {
    margin: 3rem 3rem !important;
  }
  
  .mt-1 {
    margin-top: 1rem !important;
  }

  .mt-2 {
    margin-top: 1.5rem !important;
  }
  
  .mt-3 {
    margin-top: 3rem !important;
  }

  .mr-3 {
    margin-right: 3rem !important;
  }
  
  .mb-3 {
    margin-bottom: 3rem !important;
  }
  
  .ml-3 {
    margin-left: 3rem !important;
  }
  
  .mx-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  
  .my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  
  .p-0 {
    padding: 0 0 !important;
  }
  
  .pt-0 {
    padding-top: 0 !important;
  }
  
  .pr-0 {
    padding-right: 0 !important;
  }
  
  .pb-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0 {
    padding-left: 0 !important;
  }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .p-1 {
    padding: 1rem 1rem !important;
  }
  
  .pt-1 {
    padding-top: 1rem !important;
  }
  
  .pr-1 {
    padding-right: 1rem !important;
  }
  
  .pb-1 {
    padding-bottom: 1rem !important;
  }
  
  .pl-1 {
    padding-left: 1rem !important;
  }
  
  .px-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .p-2 {
    padding: 1.5rem 1.5rem !important;
  }
  
  .pt-2 {
    padding-top: 1.5rem !important;
  }
  
  .pr-2 {
    padding-right: 1.5rem !important;
  }
  
  .pb-2 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-2 {
    padding-left: 1.5rem !important;
  }
  
  .px-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  
  .py-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .p-3 {
    padding: 3rem 3rem !important;
  }
  
  .pt-3 {
    padding-top: 3rem !important;
  }
  
  .pr-3 {
    padding-right: 3rem !important;
  }
  
  .pb-3 {
    padding-bottom: 3rem !important;
  }
  
  .pl-3 {
    padding-left: 3rem !important;
  }
  
  .px-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  
  .py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  
  .pb-4 {
    padding-bottom: 4rem;
  }

  .pl {
    padding-left: 0.5rem !important;
  }
  
  .pr {
    padding-right: 0.5rem !important;
  }
  
  .pt {
    padding-top: 0.5rem !important;
  }
  
  .pb {
    padding-bottom: 0.5rem !important;
  }
  
  .mt-quart {
    margin-top: 0.75rem !important;
  }
  
  .mt {
    margin-top: 0.5rem !important;
  }
  
  .mt-half {
    margin-top: 0.25rem !important;
  }
  
  .mb {
    margin-bottom: 0.5rem !important;
  }
  
  .mb-half {
    margin-bottom: 0.25rem !important;
  }
  
  .ml {
    margin-left: 0.5rem !important;
  }
  
  .ml-half {
    margin-left: 0.25rem !important;
  }
  
  .mr {
    margin-right: 0.5rem !important;
  }
  
  .my {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important;
  }
  
  .mx {
    margin-left: 0.3rem !important;
    margin-right: 0.3rem !important;
  }
  
  .py {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  .px {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
 

  .cv-section__description {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .cv-about {
  font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    margin-bottom: 0;
    white-space:pre-wrap;
  }

  .modal-backdrop {
    background-color: #969696 !important;
    // opacity: 0.9 !important; /* Set the desired backdrop opacity */
  }

  body {
    margin: 0px;
    font-family: var(--font-family);
    overflow-x: hidden;
  }

  .unset-padding-right {
    padding-right: unset !important;
  }

  .unset-padding-left {
    padding-left: unset !important;
  }

  .unset-margin-right {
    margin-right: unset !important;
  }

  .unset-margin-left {
    margin-left: unset !important;
  }

  .custom-link {
    cursor: pointer;
    user-select: none !important;
  
    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  body .curved {
    border-radius: 25px;  
  }
  
  .status_blue {
    color: blue;
  }

  .status_red {
    color: red;
  }

  .status_green {
    color: green;
  }

  .status_bold {
    font-weight: bold;
  }

  .size_8 {
    font-size: 8px;
  }

  .size_9 {
    font-size: 9px;
  }

  .size_10 {
    font-size: 10px;
  }

  .size_11 {
    font-size: 11px;
  }

  .size_12 {
    font-size: 12px;
  }

  .recruiter-login-btn {
    background-color: #FC20D6 !important;
  }

// example 
input.bigCheckbox { 
  width: 40px; height: 40px; 
}

.png-empty-table-img {
  height: 100px;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

